import { useContext } from 'react';
import { Box, Divider, Flex, Group, Paper, Space, Stack, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import moment from 'moment';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import SkeletonItems, { EmptyListSkeleton } from '../../Atoms/SkeletonItems';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import EmailMasterDetail from '../../Templates/Emails/EmailMasterDetail';
import ChatMessage from '../../Molecules/Message/ChatMessage';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { MailS } from '../../Service/MailS';
import ChatMessagePreview from './ChatMessagePreview';
import NewEmail from './NewEmail';
import EmailRowPhone from './EmailRowPhone';
import EmailsListHeader from './EmailsListHeader';

const EmailsSection = () => {
  const { ref, width } = useElementSize();
  const { loading, messages, pageNumber, pageCount, totalCount, setPageNumber, selectedMessage } =
    useContext(EmailBoxContext);
  const emptyBox = messages.length === 0;

  return (
    <div ref={ref}>
      {width > 700 ? (
        <DesktopSection />
      ) : (
        <>
          <EmailsListHeader phoneVersion />
          <Space h="sm" />
          {!selectedMessage && (
            <>
              {loading && <SkeletonItems count={8} height={80} />}
              <Paper>{!loading && messages.map((email) => <EmailRowPhone email={email} />)}</Paper>
              {emptyBox && <EmptyListSkeleton />}
            </>
          )}
          {selectedMessage && (
            <Paper radius="md">
              <ChatMessage phoneVersion />
            </Paper>
          )}
          {!selectedMessage && !emptyBox && (
            <PaginationNav
              pageSize={10}
              pageNumber={pageNumber}
              totalCount={totalCount}
              totalPages={pageCount}
              onChange={(newPageNumber) => setPageNumber(newPageNumber - 1)}
              entityName="Nachricht"
            />
          )}
        </>
      )}
    </div>
  );
};

const DesktopSection = () => {
  const { action } = useContext(MessageContext);
  const { selectedMessage } = useContext(EmailBoxContext);
  const replying = action === 'REPLY';

  return (
    <Flex gap="xs" sx={{ alignItems: 'flex-start' }}>
      <Box
        sx={(theme) => ({
          backgroundColor: 'white',
          minWidth: 300,
          maxWidth: 325,
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: 10,
        })}
      >
        <EmailMasterDetail />
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: 'white',
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: 10,
          flexGrow: 1,
          minHeight: 500,
        })}
        pos="relative"
      >
        <NewEmail />
        <ChatMessagePreview />
        {replying && selectedMessage && (
          <Group ml={15} mb="lg" mt="sm">
            <Divider size="xl" orientation="vertical" sx={{ borderRadius: 10 }} />
            <Stack>
              <Text color="blue">
                Am {moment(selectedMessage.lastModified).format('D MMM YYYY, [um] HH:mm')}{' Uhr '}
                schrieb{'  '}
                <Text span weight="bolder">
                  {MailS.getParticipantAddress(selectedMessage.from)}
                </Text>
                :{' '}
              </Text>
              <div dangerouslySetInnerHTML={{ __html: selectedMessage.message ?? '' }} />
            </Stack>
          </Group>
        )}
      </Box>
    </Flex>
  );
};

export default EmailsSection;
