// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Text, Alert, Button } from '@mantine/core';
import { FC } from 'react';
import { RoutingS } from '../../../Service/RoutingS';
import { ProviderHint } from '../../../Types/MailT';

const MailHint: FC<{ hint?: ProviderHint }> = ({ hint }) => {
  if (!hint) return null;
  const { text, appSpecificPasswordLink, twoStepVerificationLink } = hint;

  return (
    <>
      <Space h="sm" />
      <Alert
        key="gmail"
        p="xs"
        radius="md"
        title={
          <Text weight="bolder" size="sm">
            Wichtiger Hinweis
          </Text>
        }
        sx={(theme) => ({
          backgroundColor: theme.colors.lightBlue[0],
        })}
      >
        {appSpecificPasswordLink && (
          <>
            <>
              <Text size="xs" color="black">
                Für diesen E-Mail-Anbieter ist es notwendig, dass Du für bookitup ein App-spezifisches Passwort
                erstellst und dieses hier verwendest
              </Text>
              <Space h="md" />
            </>
            <Button
              onClick={() => {
                if (appSpecificPasswordLink) {
                  RoutingS.openInNewTab(appSpecificPasswordLink);
                }
              }}
            >
              {' '}
              App-spezifisches Passwort erstellen
            </Button>
            {twoStepVerificationLink && (
              <Text italic size="xs" weight="lighter" mt="md">
                Solltest Du kein App-spezifisches Passwort erstellen können, könnte es sein, dass Du die{' '}
                <Text
                  span
                  component="a"
                  color="blue"
                  href={twoStepVerificationLink}
                  target="_blank"
                  sx={{ cursor: 'pointer' }}
                >
                  Zwei-Faktor-Verifikation
                </Text>{' '}
                noch aktivieren musst.
              </Text>
            )}
          </>
        )}

        {text && (
          <Text size="xs" color="black">
            {text}
          </Text>
        )}
      </Alert>
      <Space h="sm" />
    </>
  );
};

export default MailHint;
