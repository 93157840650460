// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Space, Text } from '@mantine/core';
import Moment from 'react-moment';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { RoutingS } from '../Service/RoutingS';
import AvailabilityIcon from './AvailabilityIcon';
import { fetchContactById } from '../Service/restapi/contactService';
import Contact from '../Types/Contact';
import { fetchBriefestEventParticipationByContactId, fetchEventById } from '../Service/restapi/eventService';
import BookingEvent from '../Types/Event';
import { MessageDTO } from '../Types/MessageT';
import { useParams } from 'react-router-dom';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { EventS } from '../Service/EventS';

export const MessageEventBadge: FC<{ message: MessageDTO }> = ({ message }) => {
  const { id: isEventScope } = useParams();
  const { profile } = useContext(ProfileContext);
  const [eventId, setEventId] = useState<number | null>(message.eventId);

  useEffect(() => {
    // ! on selecting other email, delete event of previous selected email !
    setEventId(null);
    const toContactId = message.to ? message.to[0].contactId : null;
    const fromContactId = message.from ? message.from.contactId : null;

    if (profile?.contactId && toContactId && fromContactId) {
      if (message.eventId) {
        setEventId(message.eventId);
      } else {
        const customerId = profile?.contactId === toContactId ? fromContactId : toContactId;
        fetchEventParticipation(customerId);
      }
    }
  }, [message]);

  const fetchEventParticipation = (customerId: number) => {
    // ask event service for participating in next or last event
    fetchBriefestEventParticipationByContactId(customerId.toString(), true).then((resp) => {
      if (resp.ok) {
        resp.json().then((participations) => {
          if (participations.length > 0) {
            setEventId(participations[0].eventId);
          }
        });
      }
    });
  };

  if (isEventScope || !eventId) {
    return null;
  }

  return <EventBadge eventId={eventId.toString()} />;
};

interface Props {
  eventId: string;
}

const EventBadge: FC<Props> = ({ eventId }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Contact>();
  const [bookingEvent, setBookingEvent] = useState<BookingEvent>();
  const primaryDate = useMemo(() => bookingEvent && EventS.getPrimaryDate(bookingEvent), [bookingEvent]);

  useEffect(() => {
    fetchEventById(eventId.toString())
      .then((res) => {
        if (res.ok) {
          res.json().then(setBookingEvent);
        }
      })
      .finally(() => setLoading(false));
  }, [eventId]);

  useEffect(() => {
    if (bookingEvent) {
      const { customerId } = bookingEvent;
      fetchContactById(customerId.toString())
        .then((contact: any) => setCustomer(contact))
        .finally(() => setLoading(false));
    }
  }, [bookingEvent]);

  return (
    <>
      {loading ? (
        <Skeleton width={100} height={20} radius="md" />
      ) : (
        <>
          {bookingEvent && (
            <Group
              spacing={0}
              sx={(theme) => ({
                minHeight: 18,
                width: 'fit-content',
                paddingRight: 10,
                color: 'black',
                display: 'flex',
                fontWeight: 'lighter',
                backgroundColor: theme.colors.gray[1],
                borderRadius: 10,
                cursor: 'pointer',
              })}
              onClick={() => RoutingS.openInNewTab(`${process.env.REACT_APP_FRONTEND_URL}/events/${eventId}/details`)}
            >
              <Space w="xs" />
              <AvailabilityIcon bookingState={bookingEvent.bookingState} />
              <Space w="xs" />

              <>
                {customer && (
                  <Text className="overflow-ellipsis" size="xs" weight="bolder">
                    {customer.personDescription}
                  </Text>
                )}
                {primaryDate && (
                  <Text size="xs" ml={5}>
                    am{'  '}
                    <Moment format="DD.MM.YYYY" date={new Date(primaryDate.date)} />
                  </Text>
                )}
              </>
            </Group>
          )}
        </>
      )}
    </>
  );
};

export default EventBadge;
