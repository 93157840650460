/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {
  Button,
  Center,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
  Select,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconAt,
  IconBrandOffice,
  IconCalendarTime,
  IconChevronDown,
  IconExternalLink,
  IconLock,
  IconMailForward,
  IconNote,
  IconServer,
  IconUser,
} from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import validator from 'validator';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { useDebouncedCallback } from 'use-debounce';
import { DataS } from '../../../Service/DataS';
import { MailConfigType, MailOutBoundConfigDTO, ProviderHint, SkeletonMailOutBoundConfig } from '../../../Types/MailT';
import { MailS } from '../../../Service/MailS';
import { isDefined } from '../../../Service/ValidationS';
import { AzureS } from '../../../Service/AzureS';
import BookitupBadge from '../../../Atoms/BookitupBadge';
import { RoutingS } from '../../../Service/RoutingS';
import AutocompleteResourceTypeItem from '../../../Organismns/Resources/table/AutocompleteResourceTypeItem';
import ConfigOption from '../../../Atoms/ConfigOption';
import MailHint from './MailHint';

interface CreateConfigProps {
  profileId: number;
  onCreate: (config: MailOutBoundConfigDTO) => void;
}

export const openCreateConfigModal = (props: CreateConfigProps) =>
  openModal({
    size: 'xl',
    title: (
      <Text weight="bolder" size="xl">
        Ausgangsserver
      </Text>
    ),
    children: <CreateConfigModal {...props} />,
  });

export const CreateConfigModal: FC<CreateConfigProps> = ({ profileId, onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Partial<MailOutBoundConfigDTO>>(SkeletonMailOutBoundConfig);

  const { email, smptHost, smptPort, startTls, auth, login, password, bcc, description, type } = values ?? {};
  const validEmailAddress = email && validator.isEmail(email);
  const showSmtpSettings = type === 'SMTP';

  const providerHint =
    DataS.mailProviderHints.filter((h) => email?.includes(h.provider)).length === 1
      ? DataS.mailProviderHints.filter((h) => email?.includes(h.provider))[0]
      : undefined;

  const updateValues = (patch: Partial<MailOutBoundConfigDTO>) => setValues((prev) => ({ ...prev, ...patch }));

  useEffect(() => {
    if (validEmailAddress) {
      // setConfigType(MailS.resolveConfigType(email));
      updateValues(MailS.resolveSmtpConfig(email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onClick = async () => {
    setLoading(true);
    const resJSON = await MailS.create(values);
    setLoading(false);
    if (resJSON) {
      onCreate(resJSON);
      closeAllModals();
    }
  };

  const updateEmail = useDebouncedCallback(
    (email: string) => updateValues({ email, type: MailS.resolveConfigType(email) }),
    600,
  );

  const SmtpConfigNode = (
    <>
      <BookitupBadge title="SMTP" mt="md" />
      <Group grow mt="sm">
        <TextInput
          required
          icon={<IconServer size={20} />}
          label="SMTP-Host"
          value={smptHost === undefined ? '' : smptHost}
          onChange={(e) => updateValues({ smptHost: e.currentTarget.value })}
        />
        <Select
          required
          label="SMTP-Port"
          value={smptPort ? smptPort.toString() : null}
          onChange={(val) => updateValues({ smptPort: Number(val) })}
          data={DataS.getMailPortOptions()}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={16} />}
          rightSectionWidth={25}
        />
      </Group>
      <Group mt="sm" spacing="xl">
        <Checkbox
          size="xs"
          label="StartTLS"
          checked={startTls}
          onChange={(e) => updateValues({ startTls: e.currentTarget.checked })}
        />
        <Checkbox
          size="xs"
          checked={auth}
          onChange={(e) => updateValues({ auth: e.currentTarget.checked })}
          label="SSL-Verschlüsselt"
        />
      </Group>
    </>
  );

  const AuthConfigNode = (
    <>
      <BookitupBadge title="Authentifizierung" mt="md" mb="sm" />
      <TextInput
        required
        icon={<IconUser size={20} />}
        mb="sm"
        label="Benutzername"
        value={login}
        onChange={(e) => updateValues({ login: e.currentTarget.value })}
      />
      <MailHint hint={providerHint as ProviderHint} />
      <TextInput
        required
        icon={<IconLock size={20} />}
        label="Passwort"
        type="password"
        autoComplete="hmmm"
        name="foo"
        value={password}
        onChange={(e) => updateValues({ password: e.currentTarget.value })}
      />
    </>
  );

  const MailConfigNode = (
    <>
      <TextInput
        my="sm"
        label="Blind-Kopie-E-Mail (BCC)"
        value={bcc}
        icon={
          <Text weight="bold" size={10}>
            BCC
          </Text>
        }
        onChange={(e) => updateValues({ bcc: e.currentTarget.value })}
      />
      <TextInput
        disabled
        mb="sm"
        icon={<IconMailForward size={20} />}
        label="Antwort-E-Mail-Adresse"
        defaultValue={`profile-${profileId}@bookitup.de`}
      />
    </>
  );

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {!validEmailAddress && (
        <Text mb="md">Bitte gib hier Deine E-Mail-Adresse ein, mit der Du E-Mails senden möchtest.</Text>
      )}
      <TextInput
        required
        icon={<IconAt size={20} />}
        mt="xs"
        label="E-Mail-Adresse"
        defaultValue={email}
        onChange={(e) => updateEmail(e.currentTarget.value)}
      />
      {validEmailAddress && (
        <>
          <Select
            required
            mt="sm"
            label="Konfigurationstyp"
            data={DataS.getMailConfigOptions(MailS.isSmtpRelayAvailable(email))}
            value={type}
            onChange={(val) => updateValues({ type: val as MailConfigType })}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            rightSection={<IconChevronDown size={14} />}
            rightSectionWidth={25}
            itemComponent={AutocompleteResourceTypeItem}
          />
          {showSmtpSettings && (
            <>
              {SmtpConfigNode}
              {AuthConfigNode}
            </>
          )}
          {validEmailAddress && type !== 'AZURE' && (
            <>
              <BookitupBadge title="ANDERE" mt="xl" />
              {MailConfigNode}
              <TextInput
                mt="sm"
                icon={<IconNote size={20} />}
                label="Beschreibung (nur für Dich)"
                value={description}
                onChange={(e) => updateValues({ description: e.currentTarget.value })}
              />
            </>
          )}
          {type === 'AZURE' && (
            <Center mt="xl">
              <Button variant="default" leftIcon={<IconBrandOffice />} onClick={() => AzureS.startOAuthProcess()}>
                Konto verbinden
              </Button>
            </Center>
          )}
          {type !== 'AZURE' && (
            <Center mt={50}>
              <Button
                onClick={onClick}
                disabled={
                  loading || !validEmailAddress || (type !== 'SMTP_RELAY' && !MailS.isSubmittable(values, type))
                }
                sx={{ minWidth: 230 }}
              >
                {i18n._('actions.save')}
              </Button>
            </Center>
          )}
        </>
      )}
    </>
  );
};

interface Props {
  profileId: number;
  mailAccount: MailOutBoundConfigDTO;
  onFinish: (config: MailOutBoundConfigDTO) => void;
}

export const openMailAccountModal = (props: Props) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Ausgangsserver
      </Text>
    ),
    children: <MailAccountModal {...props} />,
  });

export const MailAccountModal: FC<Props> = ({ profileId, mailAccount, onFinish }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<MailOutBoundConfigDTO>(mailAccount);
  const [errors, setErrors] = useState({
    validEmail: true,
    validBcc: true,
  });
  const { id, email, smptHost, smptPort, startTls, auth, login, password, externalAccountId, bcc, description, type } = values;

  const updateValues = (patch: Partial<MailOutBoundConfigDTO>) => setValues((prev) => ({ ...prev, ...patch }));

  const validate = () => {
    const validEmail = validator.isEmail(email as string);
    const validBcc = !isDefined(bcc) || validator.isEmail(bcc as string);
    setErrors({
      validEmail,
      validBcc,
    });
    return validBcc && validEmail;
  };

  const onSubmit = async () => {
    if (!validate()) {
      return;
    }
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
    const cfg = await MailS.patchMailOutboundConfig(id, { login, password, bcc, description, type });

    setLoading(false);
    if (cfg) {
      onFinish(cfg);
      closeAllModals();
    }
  };

  const AuthConfigNode = (
    <>
      <BookitupBadge title="Authentifizierung" mt="md" mb="sm" />
      <TextInput
        required
        icon={<IconUser size={20} />}
        mb="sm"
        label="Benutzername"
        value={login}
        onChange={(e) => updateValues({ login: e.currentTarget.value })}
      />
      <TextInput
        required
        icon={<IconLock size={20} />}
        label="Passwort"
        type="password"
        autoComplete="hmmm"
        name="foo"
        value={password}
        onChange={(e) => updateValues({ password: e.currentTarget.value })}
      />
    </>
  );

  const MailConfigNode = (
    <>
      <TextInput
        my="sm"
        label="Blind-Kopie-E-Mail (BCC)"
        value={bcc}
        icon={
          <Text weight="bold" size={10}>
            BCC
          </Text>
        }
        onChange={(e) => updateValues({ bcc: e.currentTarget.value })}
      />
      <TextInput disabled mb="sm" label="Antwort-E-Mail-Adresse" defaultValue={`profile-${profileId}@bookitup.de`} />
    </>
  );

  const SmtpConfigNode = (
    <>
      <BookitupBadge title="SMTP" mt="md" />
      <Group grow mt="sm">
        <TextInput
          required
          icon={<IconServer size={20} />}
          label="SMTP-Host"
          value={smptHost === undefined ? '' : smptHost}
          onChange={(e) => updateValues({ smptHost: e.currentTarget.value })}
        />
        <Select
          required
          label="SMTP-Port"
          value={smptPort ? smptPort.toString() : null}
          onChange={(val) => updateValues({ smptPort: Number(val) })}
          data={DataS.getMailPortOptions()}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={16} />}
          rightSectionWidth={25}
        />
      </Group>
      <Group mt="sm" spacing="xl">
        <Checkbox
          size="xs"
          label="StartTLS"
          checked={startTls}
          onChange={(e) => updateValues({ startTls: e.currentTarget.checked })}
        />
        <Checkbox
          size="xs"
          checked={auth}
          onChange={(e) => updateValues({ auth: e.currentTarget.checked })}
          label="SSL-Verschlüsselt"
        />
      </Group>
    </>
  );

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <TextInput
        required
        disabled
        icon={<IconAt size={20} />}
        mt="xs"
        label="E-Mail-Adresse"
        value={email}
        onChange={(e) => updateValues({ email: e.currentTarget.value })}
      />
      {(MailS.isSmtpRelayAvailable(email) && !externalAccountId) && (
        <ConfigOption
          mt="lg"
          title="SMTP-Relay"
          description="Aktivieren diese Option, um ausgehende E-Mails über einen SMTP-Relay zu versenden. Dies verringert deutlich die Wahrscheinlichkeit, dass deine E-Mails im Spam landen."
          rightSection={
            <Switch
              checked={type === 'SMTP_RELAY'}
              onChange={(e) => {
                const { checked } = e.currentTarget;
                updateValues({ type: checked ? 'SMTP_RELAY' : 'SMTP' });
              }}
            />
          }
        />
      )}
      {type === 'SMTP' && SmtpConfigNode}
      {type === 'SMTP' && AuthConfigNode}
      <BookitupBadge title="Andere" mt="md" />
      {MailConfigNode}
      <TextInput
        mt="sm"
        icon={<IconNote size={20} />}
        label="Beschreibung (nur für Dich)"
        value={description}
        onChange={(e) => updateValues({ description: e.currentTarget.value })}
      />
      <Center mt={40}>
        <Button disabled={loading} onClick={onSubmit} type="submit" sx={{ minWidth: 230 }}>
          {i18n._('actions.save')}
        </Button>
      </Center>
    </>
  );
};

interface DeleteOutboundConfigProps {
  config: MailOutBoundConfigDTO;
  onDelete: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openDeleteOutboundConfigModal = (props: DeleteOutboundConfigProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Wirklich löschen?
      </Text>
    ),
    children: <DeleteOutboundConfigModal {...props} />,
  });

const DeleteOutboundConfigModal: FC<DeleteOutboundConfigProps> = ({ config, onDelete }) => {
  const { id, email } = config;
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    const deleted = await MailS.deleteMailOutboundConfig(id);
    setLoading(false);
    if (deleted) {
      onDelete();
      closeAllModals();
    }
  };

  return (
    <>
      <Text mb="md">
        Möchten Sie wirklich die Konfiguration für eine Adresse{' '}
        <Text span weight="bolder">
          {email}
        </Text>
        {'  '}entfernen?
      </Text>
      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button rightIcon={loading && <Loader size="xs" />} color="red" disabled={loading} onClick={onConfirm}>
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

export const openRevokeGrandModal = () =>
  openModal({
    title: <Title order={4}>App-Zugriff widerrufen</Title>,
    children: (
      <Text>
        Leider können wir uns nicht selbst den kompletten Zugriff entziehen, daher gehe auf
        <Text span color="blue" variant="link" sx={{ cursor: 'pointer' }}>
          {' '}
          <IconExternalLink size={16} style={{ marginBottom: 3 }} />
          https://account.microsoft.com/privacy/app-access
        </Text>
        {'  '}und entferne bookitup manuell aus der Liste.
      </Text>
    ),
  });

export const openDomainNotVerifiedModal = () =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Domain nicht bestätigt
      </Text>
    ),
    children: (
      <>
        <Text>
          Wir können Ihre Domain derzeit nicht verifizieren. Stellen Sie sicher, dass alle DNS-Einträge für Ihre Domain
          konfiguriert wurden.
        </Text>
        <Text mt="md">
          Bedenken Sie, dass es einige Zeit dauern kann, bis die DNS-Weitergabe wirksam wird. Wenn Ihre Probleme
          weiterhin bestehen, wenden Sie sich an unseren Support.
        </Text>
        <Center mt="xl">
          <Button
            leftIcon={<IconCalendarTime size={16} />}
            mt="md"
            onClick={() => {
              RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support');
              closeAllModals();
            }}
          >
            Termin vereinbaren
          </Button>
        </Center>
      </>
    ),
  });
